import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SupportContentWrapper from "../containers/SupportContent"
import Meta from "../components/Meta"
import SupportCategorySidebar from "../components/SupportCategorySidebar"
import SupportContact from "../components/SupportContact"
import SupportBreadcrumbs from "../components/SupportBreadcrumbs"
import { md, lg } from "../utils/breakpoints"

const Template = ({ data, pageContext, location }) => {
  const {
    title,
    byline,
    articles,
    meta_title,
    meta_description
  } = data.CategoryData.data
  const bcrumb = [
    { name: "Support", link: "/support/" },
    { name: title.text, link: null }
  ]

  return (
    <Layout location={location} prismicLayout={data.prismicLayout} hideSalesCTA>
      <Meta
        title={meta_title ? meta_title : "Yarno Support"}
        description={meta_description ? meta_description : "Yarno Support"}
        canonical={location.href}
      />
      <SupportBreadcrumbs bcrumb={bcrumb} />
      <SupportContentWrapper>
        <SupportCategorySidebar
          title={title.text}
          articles={articles}
          categoryUrl={data.CategoryData.uid}
        />
        <Content>
          <Heading>{title.text}</Heading>
          {byline && <p>{byline.text}</p>}
          <ListItems>
            {articles && articles.length > 0 && (
              <SupportCategorySidebar
                larger
                articles={articles}
                categoryUrl={data.CategoryData.uid}
              />
            )}
          </ListItems>
        </Content>
      </SupportContentWrapper>
      <SupportContact />
    </Layout>
  )
}

export default Template

export const query = graphql`
  query SupportCategoryQuery($id: String) {
    ...PrismicLayout
    CategoryData: prismicSupportCategory(id: { eq: $id }) {
      ...SupportCategory
    }
  }
`

const Content = styled.div`
  padding: 0.5rem;
  margin-bottom: 15px;
  display: none;
  padding: 0 15px;

  @media ${md} {
    display: block;
    padding: 0;
  }
  h3 {
    font-size: 19px;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #25c1a9;
    @media ${md} {
      margin-bottom: 4rem;
    }
  }
`

const Heading = styled.h1`
  font-size: 45px;
  line-height: 50px;
  font-weight: 700 !important;
  margin-bottom: 1rem;
  float: left;
  display: flex;
  text-align: left;
  width: 100% !important;
  color: #333;
  font-family: Raleway;

  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 38px;
  }
`

const ListItems = styled.div`
  margin: 30px 0;
  svg {
    display: none;
  }
  ul {
    li {
      width: 100%;
      a {
        color: #25c1a9;
        border-bottom: 1px solid #eee;
        padding: 12px 0;
        width: 100%;
        display: inline-block;
        &:hover {
          color: #1aa38e;
        }
      }
    }
  }
`
